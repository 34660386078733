<template>
  <div class="about">
    <div class="thumbnail-container">
      <img src="../assets/images/about_cover.png" />
    </div>
    <div class="container" style="padding-top:40px">
      <div>
        <div class="about_card card1">
          <h2 class="color-primary about_title title_font d-none d-sm-block">
            จะทำอย่างไร
          </h2>
          <h3 class="color-primary about_title d-block d-sm-none">
            จะทำอย่างไร
          </h3>
          <div class="about_captions d-none d-sm-block">
            <h4 class="font-weight-normal">
              “ให้ประชาชนคนไทยทุกคนไม่จน ไม่ต้องหวังพึ่งโชคลาภ
              ไม่ต้องเสี่ยงโชคอย่างเช่นเคย
            </h4>
            <h4 class="font-weight-normal">
              โดยมีเป้าหมาย เพื่อสร้างความเข้าใจ
              ให้ประชาชนทุกคนสามารถบรรลุเป้าหมาย
            </h4>
            <h4 class="font-weight-normal">
              ทางการเงินที่วางไว้ และมีคุณภาพชีวิตที่ดี”
            </h4>
          </div>
          <div class="d-block d-sm-none">
            <p style="margin:30px 5px 0px 5px;">
              “ให้ประชาชนคนไทยทุกคนไม่จน ไม่ต้องหวังพึ่งโชคลาภ
              ไม่ต้องเสี่ยงโชคอย่างเช่นเคย โดยมีเป้าหมาย เพื่อสร้างความเข้าใจ
              ให้ประชาชนทุกคนสามารถบรรลุเป้าหมาย ทางการเงินที่วางไว้
              และมีคุณภาพชีวิตที่ดี”
            </p>
          </div>
          <div class="bluebox"></div>
        </div>
      </div>
    </div>
    <div class="container" style="padding-top:10px">
      <div class="about_card card2">
        <h2
          class="color-primary about_title title_font d-none d-sm-block"
          style="margin-top:60px"
        >
          ที่มาและความสำคัญของโครงการ
        </h2>
        <h3 class="color-primary about_title d-block d-sm-none">
          ที่มาและความสำคัญของโครงการ
        </h3>
        <div class="about_captions d-none d-sm-block">
          <p>
            ในขณะที่ประเทศไทยกำลังอยู่ท่ามกลางวิกฤตการระบาดของโคโรน่าไวรัส
            (COVID-19) ระลอกสาม ที่ยังไม่มีแนวโน้มว่าสถานการณ์จะดีขึ้นในเร็ววัน
            การพูดถึงนโยบายฟื้นฟู
            หรือแม้แต่ปรับโครงสร้างเศรษฐกิจอาจจะดูเป็นเรื่องไกลตัว
            แต่ผลกระทบที่เกิดขึ้นกับแรงงานไทย
            รวมถึงการปรับโครงสร้างของภาคธุรกิจที่ส่งผลต่อตลาดแรงงาน
            ทำให้เราไม่สามารถชะลอการแก้ปัญหานี้ออกไปได้
          </p>
          <p>
            การแพร่ระบาดของ COVID-19 ส่งผลกระทบต่อแรงงานไทยหลากหลายมิติ
            ความเสี่ยงที่ตลาดแรงงานไทยต้องเผชิญ จากผลการวิจัยของสถาบันวิจัยป๋วย
            อึ้งภากรณ์ ( ณ เมษายน 2563) ระบุว่าแรงงานไทยกว่า 6.1
            ล้านคนได้รับผลกระทบจากมาตรการล็อกดาวน์ในการระบาดระลอกที่ 1
            โดยกลุ่มธุรกิจที่ได้รับผลกระทบสูงที่สุด คือ กลุ่มศิลปิน บันเทิง
            และนันทนาการ กลุ่มการศึกษา กลุ่มที่พักแรมและร้านอาหาร
            และกลุ่มกิจกรรมบริการด้านอื่นๆ
            ซึ่งเป็นกลุ่มอุตสาหกรรมที่ได้รับผลกระทบอย่างต่อเนื่องในการระบาดระลอกที่สองและสาม
          </p>
          <p>
            ด้วยสถานการณ์ที่เปราะบางและสภาพเศรษฐกิจที่ยังคงส่งผลกระทบต่อสภาพความเป็นอยู่ของประชาชน
            จึงเป็นที่มาของเเนวคิดและการรวมตัวกันของผู้บริหารบริษัททางการเงินการลงทุน
            ผู้บริหารบริษัทประกันชีวิต
            และวิทยากรที่มีความชำนาญด้านการวางแผนการเงิน
            ที่ต้องการนำความรู้และประสบการณ์ในการทำงาน
            โดยนำจุดดีและจุดเด่นมารวมกัน เพื่อสร้างหลักสูตรการเรียนรู้
            ทั้งภาคทฤษฎีและภาคปฎิบัติสำหรับการเป็นที่ปรึกษาการเงิน (Financial
            Advisor: FA)
            และเป็นแหล่งความรู้ทางด้านการเงินสำหรับประชาชนทั่วไปแบบครบวงจร
            โดยหวังว่าจะเป็นทางเลือกให้กับประชาชนทั่วไป
            นักศึกษาที่กำลังจะจบการศึกษา รวมถึงประชาชนที่กำลังว่างงาน
            ได้เข้ามาศึกษาหาความรู้ และพัฒนาตนเอง
            โดยเนื้อหาของหลักสูตรจะมีดังต่อไปนี้
          </p>
        </div>
        <div class="about_captions d-block d-sm-none">
          <p>
            ในขณะที่ประเทศไทยกำลังอยู่ท่ามกลางวิกฤตการระบาดของโคโรน่าไวรัส
            (COVID-19) ระลอกสาม ที่ยังไม่มีแนวโน้มว่าสถานการณ์จะดีขึ้นในเร็ววัน
            การพูดถึงนโยบายฟื้นฟู
            หรือแม้แต่ปรับโครงสร้างเศรษฐกิจอาจจะดูเป็นเรื่องไกลตัว
            แต่ผลกระทบที่เกิดขึ้นกับแรงงานไทย
            รวมถึงการปรับโครงสร้างของภาคธุรกิจที่ส่งผลต่อตลาดแรงงาน
            ทำให้เราไม่สามารถชะลอการแก้ปัญหานี้ออกไปได้
          </p>
          <p>
            การแพร่ระบาดของ COVID-19 ส่งผลกระทบต่อแรงงานไทยหลากหลายมิติ
            ความเสี่ยงที่ตลาดแรงงานไทยต้องเผชิญ จากผลการวิจัยของสถาบันวิจัยป๋วย
            อึ้งภากรณ์ ( ณ เมษายน 2563) ระบุว่าแรงงานไทยกว่า 6.1
            ล้านคนได้รับผลกระทบจากมาตรการล็อกดาวน์ในการระบาดระลอกที่ 1
            โดยกลุ่มธุรกิจที่ได้รับผลกระทบสูงที่สุด คือ กลุ่มศิลปิน บันเทิง
            และนันทนาการ กลุ่มการศึกษา กลุ่มที่พักแรมและร้านอาหาร
            และกลุ่มกิจกรรมบริการด้านอื่นๆ
            ซึ่งเป็นกลุ่มอุตสาหกรรมที่ได้รับผลกระทบอย่างต่อเนื่องในการระบาดระลอกที่สองและสาม
          </p>
          <p>
            ด้วยสถานการณ์ที่เปราะบางและสภาพเศรษฐกิจที่ยังคงส่งผลกระทบต่อสภาพความเป็นอยู่ของประชาชน
            จึงเป็นที่มาของเเนวคิดและการรวมตัวกันของผู้บริหารบริษัททางการเงินการลงทุน
            ผู้บริหารบริษัทประกันชีวิต
            และวิทยากรที่มีความชำนาญด้านการวางแผนการเงิน
            ที่ต้องการนำความรู้และประสบการณ์ในการทำงาน
            โดยนำจุดดีและจุดเด่นมารวมกัน เพื่อสร้างหลักสูตรการเรียนรู้
            ทั้งภาคทฤษฎีและภาคปฎิบัติสำหรับการเป็นที่ปรึกษาการเงิน (Financial
            Advisor: FA)
            และเป็นแหล่งความรู้ทางด้านการเงินสำหรับประชาชนทั่วไปแบบครบวงจร
            โดยหวังว่าจะเป็นทางเลือกให้กับประชาชนทั่วไป
            นักศึกษาที่กำลังจะจบการศึกษา รวมถึงประชาชนที่กำลังว่างงาน
            ได้เข้ามาศึกษาหาความรู้ และพัฒนาตนเอง
            โดยเนื้อหาของหลักสูตรจะมีดังต่อไปนี้
          </p>
        </div>
        <div class="color-primary text-big about_subtitle">
          ความรู้พื้นฐานด้านวางแผนการเงิน
        </div>
        <div class="color-primary text-big about_subtitle">
          ความรู้พื้นฐานด้านวางแผนเกษียณอายุ
        </div>
        <div class="color-primary text-big about_subtitle">
          ความรู้พื้นฐานด้านวางแผนภาษีและมรดก
        </div>
        <div class="color-primary text-big about_subtitle">
          ความรู้พื้นฐานด้านวางแผนป้องกันความเสี่ยง
        </div>
        <p class="about_captions" style="padding-bottom: 40px;">
          อาชีพที่ปรึกษาการเงิน เป็นอาชีพอิสระ
          ที่ได้การยอมรับอย่างกว้างขวางในสังคมไทยในปัจจุบันและในอนาคต
          โดยเรามั่นใจว่า จะสามารถสร้างงาน สร้างรายได้
          และสามารถนำความรู้ไปช่วยในการวางแผนการเงินให้กับประชาชน
          เมื่อทุกคนมีการวางแผนการเงินจะเป็นการช่วยเสริมสร้างความแข็งแกร่งให้กับสังคมไทยทั้งทางตรงและทางอ้อม
          ตามเป้าหมายที่เป็นจุดเริ่มต้นของโครงการที่ว่า
        </p>
      </div>
    </div>

    <div class="container" style="padding-top:40px">
      <div>
        <div class="noshadow_card">
          <h2 class="color-primary about_title title_font d-none d-sm-block">
            หลักสูตรนี้เหมาะสมกับใคร
          </h2>
          <h3 class="color-primary about_title d-block d-sm-none">
            หลักสูตรนี้เหมาะสมกับใคร
          </h3>
          <div
            class="about_captions d-none d-sm-block"
            style="padding-bottom: 40px;"
          >
            ดังนั้นการจะเป็นที่ปรึกษาการเงินที่ประสบความสำเร็จได้
            จึงจำเป็นต้องมีความรู้และมีทักษะในการทำงานที่ถูกต้อง
            โดยผ่านการให้ความรู้พื้นฐานที่ดี
            จากผู้ที่มีความรู้และประสบการณ์ในวิชาชีพโดยเฉพาะจึงมีความจำเป็น
            เสมือนหนึ่งมาอบรมกับโค๊ชในระดับชาติ
            เราจึงต้องตามหาและคัดเลือกวิทยากรที่พร้อมเสียเวลาเล็กน้อย
            เพื่อมาช่วยกันพัฒนาคน
            ที่พร้อมจะเรียนรู้การทำงานในแบบที่ปรึกษาการเงินที่แท้จริง
            รวมถึงการให้ความรู้กับประชาชนชาวไทย
            เพื่อให้มีการวางแผนการเงินอย่างถูกต้อง
          </div>
          <div class="d-block d-sm-none">
            <div style="margin:30px 5px 0px 5px; white-space: pre-line;">
              ดังนั้นการจะเป็นที่ปรึกษาการเงินที่ประสบความสำเร็จได้
              จึงจำเป็นต้องมีความรู้และมีทักษะในการทำงานที่ถูกต้อง
              โดยผ่านการให้ความรู้พื้นฐานที่ดี
              จากผู้ที่มีความรู้และประสบการณ์ในวิชาชีพโดยเฉพาะจึงมีความจำเป็น
              เสมือนหนึ่งมาอบรมกับโค๊ชในระดับชาติ
              เราจึงต้องตามหาและคัดเลือกวิทยากรที่พร้อมเสียเวลาเล็กน้อย
              เพื่อมาช่วยกันพัฒนาคน
              ที่พร้อมจะเรียนรู้การทำงานในแบบที่ปรึกษาการเงินที่แท้จริง
              รวมถึงการให้ความรู้กับประชาชนชาวไทย
              เพื่อให้มีการวางแผนการเงินอย่างถูกต้อง
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about_container">
      <div class="container row" style="padding-bottom: 40px;">
        <div
          v-for="(info, index) in infoList"
          v-bind:key="index"
          class="col-12 col-md-4 my-2"
        >
          <InfoCard :info="info" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="noshadow_card card3">
        <h2 class="color-primary about_title d-none d-sm-block">
          ค่าใช้จ่ายของผู้ใช้บริการ
        </h2>
        <h3 class="color-primary about_title d-block d-sm-none">
          ค่าใช้จ่ายของผู้ใช้บริการ
        </h3>
        <p class="about_captions d-none d-sm-block">
          เราตั้งโครงการนี้
          เพื่อให้ความรู้และแบ่งปันในสิ่งที่เป็นพื้นฐานของการวางแผนการเงิน
          ทีมงานผู้บริหารจึงมีนโยบายที่ชัดเจนจะไม่มีการเก็บค่าสมาชิกในการเรียนความรู้เบื้องต้น
          และความรู้ในการสร้างอาชีพ
          เพื่อให้ประชาชนทุกคนสามารถเข้ามาศึกษาหาความรู้และร่วมเป็นส่วนหนึ่งในการพัฒนาคุณภาพชีวิตของประชาชนชาวไทย
          ให้มีการวางแผนการเงินที่ดีขึ้น ผ่านการเรียนรู้ในโลกออนไลน์
          โดยผ่านแพลตฟอร์มของ FA Station โดยไม่มีค่าใช้จ่ายใดๆ
          ยกเว้นในอนาคตอาจจะมีค่าใช้จ่ายที่เกิดขึ้นจากหลักสูตรพิเศษ
          ค่าการจัดกิจกรรมและค่าบริการจากสถานที่ที่จัดงานสัมมนา เป็นต้น ทาง FA
          Station เห็นว่าการเก็บค่าใช้จ่ายอาจจะช่วยสนับสนุนพัฒนาแพลตฟอร์ม
          ทีมงานโอเปอเรชัน ทีมงานถ่ายทำ เเต่ในภาวะที่การแพร่ระบาดของ COVID-19
          ได้สร้างผลกระทบต่อเศรษฐกิจ สังคม และสุขภาพประชาชน ทาง FA Station
          ซึ่งมองเห็นและเข้าใจปัญหาดังกล่าว
          จึงพยายามลดค่าใช้จ่ายในการเรียนการสอนให้น้อยที่สุด
        </p>
        <p class="about_captions d-block d-sm-none">
          เราตั้งโครงการนี้
          เพื่อให้ความรู้และแบ่งปันในสิ่งที่เป็นพื้นฐานของการวางแผนการเงิน
          ทีมงานผู้บริหารจึงมีนโยบายที่ชัดเจนจะไม่มีการเก็บค่าสมาชิกในการเรียนความรู้เบื้องต้น
          และความรู้ในการสร้างอาชีพ
          เพื่อให้ประชาชนทุกคนสามารถเข้ามาศึกษาหาความรู้และร่วมเป็นส่วนหนึ่งในการพัฒนาคุณภาพชีวิตของประชาชนชาวไทย
          ให้มีการวางแผนการเงินที่ดีขึ้น ผ่านการเรียนรู้ในโลกออนไลน์
          โดยผ่านแพลตฟอร์มของ FA Station โดยไม่มีค่าใช้จ่ายใดๆ
          ยกเว้นในอนาคตอาจจะมีค่าใช้จ่ายที่เกิดขึ้นจากหลักสูตรพิเศษ
          ค่าการจัดกิจกรรมและค่าบริการจากสถานที่ที่จัดงานสัมมนา เป็นต้น ทาง FA
          Station เห็นว่าการเก็บค่าใช้จ่ายอาจจะช่วยสนับสนุนพัฒนาแพลตฟอร์ม
          ทีมงานโอเปอเรชัน ทีมงานถ่ายทำ เเต่ในภาวะที่การแพร่ระบาดของ COVID-19
          ได้สร้างผลกระทบต่อเศรษฐกิจ สังคม และสุขภาพประชาชน ทาง FA Station
          ซึ่งมองเห็นและเข้าใจปัญหาดังกล่าว
          จึงพยายามลดค่าใช้จ่ายในการเรียนการสอนให้น้อยที่สุด
        </p>
      </div>
    </div>
    <div class="container">
      <div class="noshadow_card card4">
        <p class="about_captions d-none d-sm-block">
          สิ่งที่สำคัญที่สุด หลักสูตรที่สร้างขึ้นมาเพื่อใช้ในการเรียนรู้
          มาจากความร่วมมือ ร่วมใจจากวิทยากรทุกท่าน
          ที่ทางทีมงานได้คัดเลือกและเรียนเชิญมาช่วยกันในการสร้างสรรค์สิ่งดีๆ
          แก่ประชาชนชาวไทย โดยการบรรยายผ่านระบบออนไลน์และออฟไลน์ในอนาคต
          ซึ่งทุกคนมาด้วยการให้และมีจิตใจเป็นอันหนึ่งเดียวกัน มีจุดหมายเดียวกัน
          เพื่อสร้างการเรียนรู้ไร้พรมเเดน
        </p>
        <p class="about_captions d-block d-sm-none">
          สิ่งที่สำคัญที่สุด หลักสูตรที่สร้างขึ้นมาเพื่อใช้ในการเรียนรู้
          มาจากความร่วมมือ ร่วมใจจากวิทยากรทุกท่าน
          ที่ทางทีมงานได้คัดเลือกและเรียนเชิญมาช่วยกันในการสร้างสรรค์สิ่งดีๆ
          แก่ประชาชนชาวไทย โดยการบรรยายผ่านระบบออนไลน์และออฟไลน์ในอนาคต
          ซึ่งทุกคนมาด้วยการให้และมีจิตใจเป็นอันหนึ่งเดียวกัน มีจุดหมายเดียวกัน
          เพื่อสร้างการเรียนรู้ไร้พรมเเดน
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import InfoCard from "../components/InfoCard.vue";
export default {
  name: "About",
  components: {
    InfoCard,
  },
  data() {
    return {
      infoList: [
        {
          infoId: 1,
          infoName: "ประชาชนทั่วไป",
          infoDesc:
            "ประชาชนทั่วไปที่ต้องการศึกษาหาความรู้เกี่ยวกับการวางแผนทางการเงิน หรือมีเป้าหมายในการวางแผนการเงินที่ยังไม่ชัดเจน เพื่อต้องการที่จะต่อยอดและนำไปใช้ในชีวิตประจำวัน",
          infoSrc: require("../assets/images/info_Card1.png"),
        },
        {
          infoId: 2,
          infoName: "บุคคลทั่วไปและนักศึกษาจบใหม่",
          infoDesc:
            "บุคคลทั่วไปและนักศึกษาจบใหม่ที่ต้องการพัฒนาตนเองเพื่อประกอบอาชีพที่ปรึกษาทางการเงิน เนื่องจากอาชีพที่ปรึกษาการเงินจำเป็นที่ต้องมีใบอนุญาตการทำงาน และมีความรู้ความเข้าใจเกี่ยวกับการวางแผนทางเงิน ดังนั้นผู้ที่สนใจจะต้องมีวุฒิการศึกษาในระดับปริญญาตรีขึ้นไป",
          infoSrc: require("../assets/images/info_Card2.png"),
        },
        {
          infoId: 3,
          infoName: "ที่ปรึกษาทางการเงิน",
          infoDesc:
            "ที่ปรึกษาทางการเงิน ที่ต้องการพัฒนาและเพิ่มพูนความรู้ ไว้ใช้ในการทำงานในการเป็นปรึกษาให้กับลูกค้า เพื่อให้การบริการมีประสิทธิภาพมากยิ่งขึ้น",
          infoSrc: require("../assets/images/info_Card3.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.about_container {
  display: flex;
  justify-content: center;
}
.about_card {
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 30px 20px;
}

.noshadow_card {
  text-align: center;
  padding: 30px 20px;
}
.about_title {
  /* padding-top: 20px; */
  margin: 21px;
}
.title_font {
  font-size: 36px;
  font-weight: 700;
}
.about_subtitle {
  margin: 26px;
}
.about_captions {
  margin: 30px;
  width: 95%;
  margin: auto;
}

.card1 {
  position: relative;
  width: 100%;
  /* height: 255px; */
}
.card2 {
  width: 100%;
  height: auto;
}
.card3 {
  width: 100%;
  height: auto;
}
.card4 {
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.bluebox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 22px;
  background-color: #07c5f8;
}

.thumbnail-container {
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-top: 33.33%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.thumbnail-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}
</style>
