<template>
  <div class="card-container">
    <div class="info-card d-flex flex-column">
      <div class="thumbnail-container">
        <img :src="info.infoSrc" alt="" />
      </div>
      <div class="d-flex flex-column h-100 content">
        <p
          class="text-big font-weight-bold color-primary"
          style="text-align:center;"
        >
          {{ info.infoName }}
        </p>
        <p class="description">
          {{ info.infoDesc }}
        </p>
        <div>
          <p class="m-0">{{ info.instructor }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["info"],
};
</script>
<style scoped>
a,
a:hover {
  color: inherit;
  text-decoration: none;
}

.thumbnail-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.thumbnail-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
}

.card-container {
  height: 100%;
  max-width: 370px;
  margin: 0 auto;
}

.info-card {
  /* cursor: pointer; */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  height: 100%;
}

.info-card .content {
  padding: 10px;
}

.description {
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 1rem 0;
}
</style>
